<template>
  <Fragment>
    <v-progress-linear
        :active="$apollo.queries.business.loading"
        :indeterminate="$apollo.queries.business.loading"
        absolute
        top
        color="#ff3e00"
    ></v-progress-linear>
    <v-container fluid class="fill-height">
      <v-row class="fill-height">
          <v-col md="4" lg="4" class="center">
            <search @search="search"/>
          </v-col>
          <v-spacer></v-spacer>
          <v-col md="2" lg="2"  >
            <create-business-modal @refresh="refresh"></create-business-modal>
          </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col md="12" lg="12">
          <v-skeleton-loader v-if="$apollo.queries.business.loading" :loading="$apollo.queries.business.loading" type="table"></v-skeleton-loader>
          <v-simple-table
              fixed-header
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-center">
                  Approval Status
                </th>
                <th class="text-center">
                  Closed
                </th>
                <th class="text-center">
                  Delete
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in business"
                  :key="item.id"
              >
                <td>
                  <router-link
                      :to="{ name: 'business', params: { businessID: item.id } }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td class="text-center">
                  <select-approval-status
                      :id="item.id"
                      :approval-state="item.approvalState"
                      @change-approval="changeApproval"
                  />
                </td>
                <td class="text-center">
                  <toggle-closed-state :business="item"/>
                </td>
                <td class="text-center">
                  <ConfirmDeleteModal :loading="deleteLoading" :business="item" :handleDelete="confirmDelete"/>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </Fragment>
</template>
<script>
import {
  ConfirmDeleteModal,
  SelectApprovalStatus,
  ToggleClosedState,
  CreateBusinessModal,
  Search,
} from "@/components";
import {Fragment} from "vue-fragment";
import {GET_ADMIN_DATA} from "@/modules/admin/graph/queries";
import {
  DELETE_BUSINESS,
  UPDATE_BUSINESS_APPROVAL_STATE,
} from "@/components/modals/graph/mutations";

export default {
  name: "AdminBusiness",
  components: {
    CreateBusinessModal,
    Search,
    ConfirmDeleteModal,
    ToggleClosedState,
    SelectApprovalStatus,
    Fragment,
  },
  data: function () {
    return {
      deleteLoading: false,
      searchTerm: '%%',
      business: [],
      selected: {},
      businessList: [],
    };
  },
  methods: {
    search(e) {
      if (e === null) {
        this.searchTerm = "%" + '' + "%";
      } else {
        this.searchTerm = "%" + e + "%";
      }
    },
    refresh: function () {
      this.$apollo.queries.business.refetch();
    },
    deleteBusiness(item) {
      this.selected = item;
      this.$modal.show("ConfirmDeleteModal");
    },
    confirmDelete(id) {
      if (id) {
        this.deleteLoading = true
        this.$apollo
            .mutate({
              mutation: DELETE_BUSINESS,
              variables: {
                id: id,
              },
            })
            .then(() => {
              this.$notify({
                group: "admin",
                type: "success",
                title: "Business Deleted",
              });
              this.refresh();
            })
            .catch(() => {
              this.$notify({
                group: "admin",
                type: "error",
                title: "Business Delete Failed",
              });
            }).finally(()=>{
          this.deleteLoading = false
        })
      }
    },
    changeApproval(value) {
      this.$apollo
          .mutate({
            mutation: UPDATE_BUSINESS_APPROVAL_STATE,
            variables: {
              id: value.id,
              state: value.state,
            },
          })
          .then(() => {
            this.$notify({
              group: "admin",
              type: "success",
              title: "Approval State Updated",
            });
          })
          .catch(() => {
            this.$notify({
              group: "admin",
              type: "error",
              title: "Approval State Update Failed",
            });
          });
    },
  },
  apollo: {
    business: {
      fetchPolicy: "cache-and-network",
      query: GET_ADMIN_DATA,
      variables() {
        return {
          name: this.searchTerm,
        };
      },
    },
  },
};
</script>
<style scoped>
.total p:last-child {
  font-size: 25px;
}
</style>
