import { render, staticRenderFns } from "./AdminBusiness.vue?vue&type=template&id=6b5c0482&scoped=true&"
import script from "./AdminBusiness.vue?vue&type=script&lang=js&"
export * from "./AdminBusiness.vue?vue&type=script&lang=js&"
import style0 from "./AdminBusiness.vue?vue&type=style&index=0&id=6b5c0482&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5c0482",
  null
  
)

export default component.exports