import gql from "graphql-tag";

export const GET_ADMIN_DATA = gql`
    query GetAdminData($name: String) {
        business(where: { name: { _ilike: $name } }, order_by: { name: asc }) {
            id
            name
            approvalState
            closed
            categories {
                category {
                    name
                }
            }
        }
        businesses: business_aggregate {
            aggregate {
                count
            }
        }
        locations: location_aggregate {
            aggregate {
                count
            }
        }
        users: users_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_USERS = gql`
    query Users {
        users {
            id
            email
            admin
        }
    }
`